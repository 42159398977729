import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from './loader/loader.module';
import { HeaderComponent } from './header/header.component';
import { MainPageComponent } from './main-page/main-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FileUploadModule} from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { OperatorNavComponent } from './operator-nav/operator-nav.component';
import { CorporateAdminNavComponent } from './corporate-admin-nav/corporate-admin-nav.component';
import { SiteAdminNavComponent } from './site-admin-nav/site-admin-nav.component';
import { SiteUserNavComponent } from './site-user-nav/site-user-nav.component';
import { MultiSiteUserNavComponent } from './multisite-user-nav/multisite-user-nav.component';
import { UserManagementModule } from './user-management/user-management.module';
import { RouteGuard } from './route.guard';
import { RouteChildGuard } from './route-child.guard';
import { NgxEchartsModule } from 'ngx-echarts';
import { ColorPickerModule } from 'ngx-color-picker';
import { LabHeaderComponent } from './lab-header/lab-header.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {CalendarModule} from 'primeng/calendar';
import {
  GoogleLoginProvider,
} from 'angularx-social-login';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { ExecutiveNavComponent } from './executive-nav/executive-nav.component';
import { PeriodicReportComponent } from './periodic-report/periodic-report.component';
import { NgSelectModule } from '@ng-select/ng-select';



// datadogRum.init({
//     applicationId: '907a419a-85b5-48d8-8cfe-7176e7a70023',
//     clientToken: 'pub4808d134caf5d63d3e6dd9cab32ec64c',
//     site: 'ap1.datadoghq.com',
//     service: 'ide',
//     env: 'environment',
//     // Specify a version number to identify the deployed version of your application in Datadog 
//     // version: '1.0.0', 
//     sessionSampleRate: 100,
//     sessionReplaySampleRate: 20,
//     trackUserInteractions: true,
//     trackResources: true,
//     trackLongTasks: true,
//     defaultPrivacyLevel: 'mask-user-input',
// });

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name:'login' //you can use any name here
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainPageComponent,
    OperatorNavComponent,
    CorporateAdminNavComponent,
    SiteAdminNavComponent,
    SiteUserNavComponent,
    MultiSiteUserNavComponent,
    LabHeaderComponent,
    ChangePasswordComponent,
    ErrorPageComponent,
    ExecutiveNavComponent,
    PeriodicReportComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FileUploadModule,
    TableModule,
    NgbModule,
    ToastModule,
    CarouselModule,
    UserManagementModule,
    DialogModule,
    SidebarModule,
    ColorPickerModule,
    SocialLoginModule,
    CalendarModule,
    LoaderModule,
    NgSelectModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ], exports: [HeaderComponent, MainPageComponent],
  providers: [MessageService, RouteGuard, RouteChildGuard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // provider: new GoogleLoginProvider('690988255366-u0d935begk83mbm02qp8vvp26tbg568j.apps.googleusercontent.com',
            provider: new GoogleLoginProvider('690988255366-epi4918ft2506v559p4rch6dfmj3o2a9.apps.googleusercontent.com',googleLoginOptions)
            
          }
          
        ],
        onError: (err) => {
          //err
        },
      
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
