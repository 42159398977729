import {Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';
import { DataShareService } from '../service/data-share.service';
import { Observable } from 'rxjs';
import { Links } from '../models/report.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-executive-nav',
  templateUrl: './executive-nav.component.html',
  styleUrls: ['./executive-nav.component.css']
})
export class ExecutiveNavComponent implements OnInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public selectedSite = '';
  public sitesList: any[] = [];
  public siteinfo:any = [];
  ch_pass : any;
  labSidebardisplay: boolean;
  changePasswordShow: boolean;

  showLinks = false;
  
  public links$: Observable<Links[]>;
  constructor(private userService: UserManagementService, private datashareSvc: DataShareService, 
    private router: Router
  ) { }

  ngOnInit(): void {
    

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showLinks = this.router.url === '/';
    });

    // Handle initial URL on page load
    this.showLinks = this.router.url === '/';

    this.links$ = this.datashareSvc.links$$;
  }
  logout() {
    this.userService.logout();
  }
  public onLinkClick(link: Links) {
    this.datashareSvc.selectedLink$$.next(link);
  }
}
