import { Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-corporate-admin-nav',
  templateUrl: './corporate-admin-nav.component.html',
  styleUrls: ['./corporate-admin-nav.component.css']
})
export class CorporateAdminNavComponent implements OnInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  sitename : any;
  constructor(private userService: UserManagementService,private router: Router) { 
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getSiteName(); 
      }
    });
  }
  logout() {
    this.userService.logout();
  }
  getSiteName(){
    this.sitename= this.userService.getCurrentSitename();
  }
  setSiteName(){
    this.userService.setCurrentSitename('');
  }
}
